import { Box, Heading, Text, Flex, Button } from "@chakra-ui/react";
import { Coin } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { CLIENT_HOME_ROUTE } from "configuration";
import React  from 'react';

function MoneyOnTheWay() {
  const navigate = useNavigate();

  const onContinue = async () => {
    navigate(CLIENT_HOME_ROUTE);
    window.location.reload();
  };

  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={"green.500"}
          rounded={"50px"}
          w={"60px"}
          h={"60px"}
          textAlign="center"
        >
          <Coin color="white" size={35} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Estamos validando toda tu información
      </Heading>
      <Text color={"gray.500"}>
          Cuando hayamos verificado exitosamente tu información y hayamos localizado a tu contacto de referencia, tu dinero estará en camino!
          <br/>
          Este proceso suele ser rápido, aunque puede tomar hasta 48 horas hábiles.
      </Text>
      <br />
      <Button
        type="submit"
        colorScheme={"blue"}
        variant={"solid"}
        onClick={onContinue}
      >
        Recargar
      </Button>
    </Box>
  );
}

export default MoneyOnTheWay;
